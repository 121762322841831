@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700;900&display=swap');

* {
	outline: none;
	border: none;
	margin: 0;
	padding: 0;
	font-family: 'Nunito Sans', sans-serif;
	font-weight: normal;
	font-size: 12px;
}

body {
  background-image: url('../images/background.png');
  background-color: rgb(34,31,32);
}

.pulse {
  animation: pulse-animation 1.5s infinite;
}

.grecaptcha-badge {
  visibility: hidden;
}

@keyframes pulse-animation {
  0% {
    width: 0px;
    right: 0px;
  }
  50% {
    right: 0px;
    width: 100px;
  }
  100% {
    left: 0px;
    width: 0px;
  }
}